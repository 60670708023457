import { Global } from '~/global'
import { Maybe } from '~/app/types'

/**
 * Helper method that returns the value of a URL parameter, in the current page's URL
 * @param name The name of the URL param we are looking for
 * @returns String The value of the URL param
 */
export function getUrlParameter(name) {
    return getUrlParameterInSource(name, location.search)
}

/**
 * Helper method that returns the value of a URL parameter, given the name
 * @param name The name of the URL param we are looking for
 * @param source the text to search for the URL parameter in
 * @returns String The value of the URL param
 */
export function getUrlParameterInSource(name, source) {
    name = name.replace(/\[/, String.raw`\[`).replace(/]/, String.raw`\]`)
    const regex = new RegExp(String.raw`[\?&]` + name + '=([^&#]*)'),
        results = regex.exec(source)
    return results === null ? '' : decodeURIComponent(results[1].replaceAll('+', ' '))
}

/**
 * Helper method that returns if viewing on native mobile.
 * @returns true if native-mobile is attached as a body css class, false otherwise
 */
export function isNativeMobile() {
    return Global.IS_NATIVE_MOBILE
}

/**
 * Gets the Base URL for the OrgWiki API.  Uses the latest version (v2 as of now)
 * @returns {string}.  In the form of a relative URL, like "/api/v2"
 */
export function getAPIBaseURL() {
    return getAPIBaseURLForVersion('v2')
}

/**
 * Gets the Base URL for the OrgWiki API given an optional passed in version
 * @param version The version of the API to use.
 * @returns {string} In the form of a relative URL, like "/api"
 */
export function getAPIBaseURLForVersion(version) {
    let url = '/api'
    if (version) {
        url = url + '/' + version
    }
    return url
}

/**
 * Gets the value of a particular query variable
 * @param {string} variable The variable name
 * @returns {string} The query value
 */
export function getQueryVariable(variable) {
    const query = window.location.search.slice(1)
    const vars = query.split('&')
    for (const var_ of vars) {
        const pair = var_.split('=')
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent((pair[1] || '').replaceAll('+', '%20'))
        }
    }
}

/**
 * Gets all query variables
 * @returns {object} The query map
 */
export function getAllQueryVariables() {
    const query = window.location.search.slice(1)
    const vars = query.split('&').filter(Boolean)
    const obj = {}
    for (const var_ of vars) {
        const pair = var_.split('=')
        const k = decodeURIComponent(pair[0])
        obj[k] = decodeURIComponent(pair[1]).replaceAll('+', '%20')
    }
    return obj
}

/**
 * Convert a flag value to a boolean.  Only 1 or "1" are considered true.
 *
 * @param value
 */
export const flag = value => value === 1 || value === '1'

export const getQueryString = queryDict => {
    let added = false
    return Object.keys(queryDict).reduce((acc, k) => {
        const val = queryDict[k]
        if (val || val === 0 || val === '') {
            const u = `${acc}${added ? '&' : '?'}${encodeURIComponent(k)}=${encodeURIComponent(val)}`
            added = true
            return u
        } else {
            return acc
        }
    }, '')
}
export const getSortClass = (prop: string, ordering: Maybe<string[]>): 'active' | '-active' | '' => {
    const order = (ordering || []).join(',')
    if (prop === order) {
        return 'active'
    } else if ((prop.startsWith('-') ? prop.slice(1) : prop) === (order.startsWith('-') ? order.slice(1) : order)) {
        return '-active'
    } else {
        return ''
    }
}
