import React, { Fragment } from 'react'
import moment from 'moment-timezone'

import { Global } from '~/global'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import * as Constants from '~/utils/Constants'
import { escapeHtml } from './stringUtils'
import { API_ERROR_CODES } from '~/utils/ServerApi'
import { TFunction } from 'i18next'

export const defunctKeyPrefixes = [
    '__',
    'lscache-ow-employee-',
    'ow-prefetch-idx',
    'lscache-ow-employeeblob-',
    'ow-cache-timestamp',
    'ow-employeemap',
    'ow-cache-timestamp',
]

/**
 * Takes the provided employee's 'timezone_name' property, passes it to the
 * moment.js library and returns a formatted string response
 * @param employee
 * @returns {string}
 */
export function getLocalTimeForEmployee(employee) {
    let localTime = ''
    if (employee.timezone_name) {
        try {
            localTime = moment.tz(employee.timezone_name).format('h:mma z')
        } catch {
            //
        }
    }
    return localTime
}

/**
 * Object that holds the custom section related info
 */
export type CustomSectionType = {
    TYPE: number
    API_NAME: string
}
export const CustomSections: { [name: string]: CustomSectionType } = {
    ABOUT_ME: {
        TYPE: 1,
        API_NAME: 'about',
    },
    RESPONSIBILITIES: {
        TYPE: 2,
        API_NAME: 'responsibilities',
    },
    THIS_QUARTER: {
        TYPE: 3,
        API_NAME: 'this_quarter',
    },
    PROJECTS: {
        TYPE: 4,
        API_NAME: 'projects',
    },
    KEY_ACCOUNTS: {
        TYPE: 5,
        API_NAME: 'key_accounts',
    },
    CUSTOM: {
        TYPE: 6,
        API_NAME: 'custom',
    },
}

export function getCustomSectionFromType(type: number): CustomSectionType | undefined {
    // Get all the CustomSections in this object
    return Object.keys(CustomSections)
        .map(k => CustomSections[k])
        .find(({ TYPE }) => TYPE === type)
}

/**
 * Checks in an event is a normal (not ctrl/option/right) left click
 * @param {Number} which Indicated left vs right click
 * @param {Boolean} [ctrlKey] Indicates if control was pressed
 * @param {Boolean} [metaKey] Indicates if option was pressed
 * @returns {boolean}
 */
export function isLeftClick({ nativeEvent: { which }, ctrlKey, metaKey }) {
    return which === 1 && !ctrlKey && !metaKey
}

export function onFilteredLeftClick(fun) {
    return e => {
        if (isLeftClick(e)) {
            e.preventDefault()
            fun(e)
        }
    }
}

/**
 * Gets the full url for a export file
 * @param {string} path The export file path (eg. orgwiki/img/balloons.png)
 * @return {string} The full path to the export file
 */
export function staticPath(path) {
    return `${Global.STATIC_PREFIX}${path}`
}

export function isAreaEnabled() {
    const company = OWAuthDataModel.getAuthCompany()
    return Boolean(company?.company_config?.area_enabled)
}

export function isTimeOffEnabled() {
    const company = OWAuthDataModel.getAuthCompany()
    return Boolean(company?.company_config?.time_off_enabled)
}

/**
 * Determine if a feature is active or not
 * @param {string} featureName The feature name (from Constants.Features)
 * @returns {Boolean} True iff the feature is active
 */
export function hasFeature(featureName) {
    const flagMap = Constants.CompanyFeatureFlagMap[featureName]
    if (flagMap) {
        const company = OWAuthDataModel.getAuthCompany()
        return company ? company[flagMap.property] : false
    } else {
        const features = Global.OW_AUTH_DATA?.features
        return features?.indexOf(featureName) !== -1
    }
}

/**
 * Determine if wiki profile edits are enabled
 * @returns {Boolean}
 */
export function hasWikiProfileEdits() {
    return hasFeature(Constants.Features.WIKI_PROFILE_EDITS_ENABLED)
}

/**
 * Determines if dotted line managers are enabled
 * @returns {Boolean}
 */
export function hasDottedLines() {
    return hasFeature(Constants.Features.DOTTED_LINES_ENABLED)
}

/**
 * Determines if skills are enabled
 * @returns {Boolean}
 */
export function hasSkills() {
    return hasFeature(Constants.Features.TAGS_ENABLED)
}

/**
 * Determines is an error is a coded error or not
 * @param {Object} responseJSON The response body json
 * @returns {Boolean}
 */
export function isCodedError({ responseJSON }) {
    return responseJSON && responseJSON.code && typeof responseJSON === typeof {}
}

/**
 * Formats and translates a validation error
 * @param {Object} e The validation error detail
 * @param {Function} t The translation function
 * @returns {Object} The formatted error
 */
export function formatValidationError(e?: { responseJSON?: { detail?: string } }, t?: TFunction) {
    const detail = e?.responseJSON?.detail ?? ''
    const subFormat = subInfo => {
        if (subInfo.code === API_ERROR_CODES.VALIDATION_ERROR) {
            return formatValidationError({ responseJSON: subInfo }, t)
        } else if (typeof subInfo === typeof {} && !subInfo.code) {
            return formatValidationError({ responseJSON: { detail: subInfo } }, t)
        } else {
            return t && t(subInfo.code, subInfo.detail)
        }
    }
    return Object.fromEntries(
        Object.keys(detail).map(field => [
            field,
            Array.isArray(detail[field]) ? detail[field].map(subInfo => subFormat(subInfo)) : subFormat(detail[field]),
        ])
    )
}

/**
 * Get an error code from an error
 * @param {Object} responseJSON The response json
 * @returns {String}
 */
export function getErrorCode({ responseJSON }) {
    return responseJSON.code
}

/**
 * Formats and translates a coded error
 */
export function formatCodedError({ responseJSON }: { responseJSON?: { code: number; detail: string } }, t: TFunction) {
    return t(`${responseJSON?.code ?? ''}`, responseJSON?.detail ?? '')
}

/**
 * Determines if an error is a form validation error
 * @param {Number} status The response status code
 * @param {Object} responseJSON The response body json
 * @returns {Boolean}
 */
export function isValidationError({ responseJSON }) {
    return Boolean(isCodedError({ responseJSON }) && responseJSON.code === API_ERROR_CODES.VALIDATION_ERROR)
}

/**
 * Displays non-field errors in a bootstrap modal
 * @param {Number} status The http status code of the response
 * @param {object} [responseJSON] The response json
 * @param {string} [statusText] The status text
 * @param {function} t The translation function
 */
export type ErrorResponse = {
    status?: number
    statusText?: string
    responseJSON?: {
        code: number
        detail: string
        non_field_errors?: []
    }
}

export function getNonFormErrorMessage({ status, responseJSON, statusText }: ErrorResponse, t): string {
    if (isValidationError({ responseJSON })) {
        // We don't have a form to work with
        const fieldErrors = formatValidationError({ responseJSON }, t)
        const msg = Object.keys(fieldErrors)
            .map(field => {
                // Replace -_. with ' ', capitalize words
                const formattedField = field.replaceAll(/[._-]+/g, ' ').replaceAll(/(^|\s)\S/g, l => l.toUpperCase())
                return `<strong>${formattedField}:</strong> ${fieldErrors[field].join(' ')}`
            })
            .join('\n')
        return msg
    } else if (isCodedError({ responseJSON })) {
        const message = formatCodedError({ responseJSON }, t)
        return message
    } else if (status === 400 && responseJSON?.non_field_errors) {
        const status = 400
        const statusText = responseJSON.non_field_errors.join(', ')
        return t('profile_edit_page.processing_error', { status, statusText })
    } else if (responseJSON?.detail) {
        return escapeHtml(responseJSON.detail)
    } else if (status && statusText) {
        return t('profile_edit_page.processing_error', { status, statusText })
    } else {
        return t('something_went_wrong')
    }
}

/**
 * Flushes all stale localstorage keys
 */
export function flushStaleLocalStorage() {
    Object.keys(window.localStorage)
        .filter(k => defunctKeyPrefixes.some(pre => k.startsWith(pre)))
        .forEach(k => window.localStorage.removeItem(k))
}

/**
 * Gets the label for the "Team" fact for search
 * @param t
 */
export function getTeamFacetLabel(t) {
    if (hasFeature(Constants.Features.SHOW_TEAMS_DEMO)) {
        return t('advanced_search.organization')
    } else {
        return t('advanced_search.team')
    }
}

/**
 * Navigates when a fatal error is encountered while loading the main page data for a page (such as the employee
 *  for the profile page)
 * @param {ErrorResponse} e the eror
 */
export function onMainLoadError(e) {
    if (e.statusText === 'abort' || e.status === 499) {
        return
    }
    // if (e.status === 404) {
    //     window.location.replace("/404/");
    // } else if (e.status === 403) {
    //     window.location.reload();
    // } else {
    //     window.location.replace("/500/");
    // }
}

function _parseTags(s, tagRe, getTag) {
    const parts = s.split(tagRe)
    const els = parts.map((fragment, idx) => (idx % 2 === 0 ? fragment : getTag(fragment)))
    return <Fragment>{els}</Fragment>
}

/**
 * Parses a highlight string from ES and returns a React Fragment
 * @param {string} s
 * @returns {React.Fragment}
 */
export function parseHighlights(s) {
    return _parseTags(s, /<\/?em>/g, f => <em>{f} </em>)
}

/**
 * Parses notification text from the server and returns a React Fragment
 * @param {string} s
 * @returns {React.Fragment}
 */
export function parseNotifications(s) {
    return _parseTags(s, /<\/?strong>/g, f => <strong>{f} </strong>)
}

/**
 * Removes accents from a string, and returns the non-accented string
 * From: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @param {string} s
 * @returns {string}
 */
export function removeAccents(s) {
    return s.normalize('NFD').replaceAll(/[\u0300-\u036F]/g, '')
}

/**
 * Determines if the languagePicker should be displayed
 * @returns {Boolean}
 */
export function languagePickerEnabled() {
    const enabledLanguages = Global.OW_AUTH_DATA.enabled_languages
    return (enabledLanguages || []).length > 1
}

/**
 * Merge two objects, merging subkeys as well
 * b wins in all conflicts
 * @param {Object} a
 * @param {Object} b
 * @returns {Object} a new merged object
 */
const getValue = value => structuredClone(value)
export function mergeObjects(a, b) {
    const keyNames = Object.keys(
        Object.fromEntries(
            Object.keys(a || {})
                .concat(Object.keys(b || {}))
                .map(k => [k, true])
        )
    )
    return keyNames.reduce((acc, k) => {
        if (a[k] !== undefined && b[k] !== undefined) {
            if (typeof a[k] === typeof b[k] && typeof a[k] === typeof {}) {
                acc[k] = mergeObjects(a[k], b[k])
            } else {
                acc[k] = getValue(b[k])
            }
        } else if (a[k] === undefined) {
            acc[k] = getValue(b[k])
        } else {
            acc[k] = getValue(a[k])
        }
        return acc
    }, {})
}

/**
 * Debounce function for specified time period
 * adapted from https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940
 * @param func function to be debounced
 * @param waitFor wait period for debounce
 */
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: NodeJS.Timeout

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
        new Promise(resolve => {
            if (timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => resolve(func(...args)), waitFor)
        })
}
